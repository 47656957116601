import React from 'react';
import { lazy } from "react";
import { Routes, Route } from 'react-router-dom';
// import LandingPage from '../Components/LandingPage/LandingPage';
// import Login from '../Components/Login/Login';
// import Signup from '../Components/Signup/Signup';
import PrivateRoute from "../Components/PrivateRoute/PrivateRoute"
// import Dashboard from '../Components/Dashboard/Dashboard';
// import Straddle from '../Components/Straddle/Straddle';
// import FuturesChart from '../Components/FuturesChart/FuturesChart';
// import IVPriceOI from '../Components/IVPriceOI/IVPriceOI';
// import OIGraph from '../Components/OIGraph/OIGraph';
// import PCRChart from '../Components/PCRChart/PCRChart';
// import Backtesting from '../Components/Backtesting/Backtesting';

const LandingPage = lazy(() => import("../Components/LandingPage/LandingPage"));
const Login = lazy(() => import("../Components/Login/Login"));
const Signup = lazy(() => import("../Components/Signup/Signup"));
const Dashboard = lazy(() => import("../Components/Dashboard/Dashboard"));
const Straddle = lazy(() => import("../Components/Straddle/Straddle"));
const FuturesChart = lazy(() => import("../Components/FuturesChart/FuturesChart"));
const IVPriceOI = lazy(() => import("../Components/IVPriceOI/IVPriceOI"));
const OIGraph = lazy(() => import("../Components/OIGraph/OIGraph"));
const PCRChart = lazy(() => import("../Components/PCRChart/PCRChart"));

const allRoutes = () => {
    return (
        <>
            <Routes >
                <Route path="/" element={<LandingPage />} />
                <Route path="/login" element={<Login />} />
                <Route path="/signup" element={<Signup />} />
                <Route exact path='/dashboard' element={<PrivateRoute />}>
                    <Route exact path='/dashboard' element={<Dashboard />} />
                </Route>
                <Route exact path='/straddle' element={<PrivateRoute />}>
                    <Route exact path='/straddle' element={<Straddle />} />
                </Route>
                <Route exact path='/futures-chart' element={<PrivateRoute />}>
                    <Route exact path='/futures-chart' element={<FuturesChart />} />
                </Route>
                <Route exact path='/iv-price-oi' element={<IVPriceOI />}>
                    <Route exact path='/iv-price-oi' element={<IVPriceOI />} />
                </Route>
                {/* <PrivateRoute exact path='/dashboard' component={Dashboard} /> */}
                {/* <PrivateRoute exact path='/straddle' component={Straddle} />
                <PrivateRoute exact path='/iv-price-oi' component={IVPriceOI} />
                <PrivateRoute exact path='/oi-graph' component={OIGraph} />
                <PrivateRoute exact path='/futures-chart' component={FuturesChart} />
                <PrivateRoute exact path='/pcr-chart' component={PCRChart} /> */}
                {/* <PrivateRoute exact path='/backtesting' component={Backtesting} /> */}
            </Routes >
        </>
    );
}

export default allRoutes;