import React from "react"
import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from "../../Contexts/AuthContext"

export default function PrivateRoute({ component: Component, ...rest }) {
  const { currentUser } = useAuth()
  function isAuthenticated() {
    if (currentUser == null) {
      return false;
    }
    // return AuthenticatedUsers.includes(currentUser.email) ;
    return true;
  }

  return isAuthenticated() ? <Outlet /> : <Navigate to="/login" />;   

}