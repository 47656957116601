import firebase from 'firebase/compat/app';
import 'firebase/compat/auth'
import 'firebase/compat/firestore'

const fire = firebase.initializeApp({
    apiKey: "AIzaSyC5vwZXOI5J8_hQyf87Lx1UmBqtzEEiGKE",
    authDomain: "eazy-options.firebaseapp.com",
    projectId: "eazy-options",
    storageBucket: "eazy-options.appspot.com",
    messagingSenderId: "961842902601",
    appId: "1:961842902601:web:e65f532ea3c26fcdc1f198",
    measurementId: "G-G112604LZF"
})



export const auth = fire.auth();
export const firestore = firebase.firestore();

export default fire