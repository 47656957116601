// Loader.js
import React from 'react';
import * as Loader from "react-loader-spinner";
import "./Loader.css";

const LoaderComponent = () => {
	return (
		<div className="loader-container">
			<div className="loader">
				<Loader.TailSpin />
			</div>
		</div>
	);
};

export default LoaderComponent;
