import React, { useContext, useState, useEffect } from "react"
import { auth, firestore } from "../firebase"

const AuthContext = React.createContext()

export function useAuth() {
  return useContext(AuthContext)
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState()
  const [loading, setLoading] = useState(true)

  async function signup(email, password, username) {
    return auth.createUserWithEmailAndPassword(email, password).then(cred=> {
        return firestore.collection('users').doc(cred.user.uid).set({
          username : username,
          email : email,
          credit : 10,
        });
    })
  }

  function login(email, password) {
    return auth.signInWithEmailAndPassword(email, password)
  }

  function logout() {
    return auth.signOut()
  }

  function resetPassword(email) {
    return auth.sendPasswordResetEmail(email)
  }

  function updateEmail(email) {
    return currentUser.updateEmail(email)
  }

  function updatePassword(password) {
    return currentUser.updatePassword(password)
  }

  function createUserDocument(user,additionalData) {
    const userRef = firestore.doc(`users/${user.uid}`);

    const snapshot = userRef.get();

    if (!snapshot.exists) {
      const email = user.email;
      const username = additionalData;
      try {
        userRef.set({
          email,
          username,
        });
      } catch (error) {
        console.log('Error in creating user', error);
      }
    }
  }

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      setCurrentUser(user)
      setLoading(false)
    })

    return unsubscribe
  }, [])

  const value = {
    currentUser,
    login,
    signup,
    logout,
    resetPassword,
    updateEmail,
    updatePassword,
    createUserDocument
  }

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  )
}