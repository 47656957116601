import './App.css';
import Routes from './Routes/Router'
import { AuthProvider } from './Contexts/AuthContext';

function App() {
  return (
    <div className="App">
      <AuthProvider>
        <Routes />
      </AuthProvider>
    </div>
  );
}

export default App;
